<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-equip
            label="설비"
            type="search"
            name="equipmentCd"
            v-model="searchParam.equipmentCd">
          </c-equip>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            label="정비년월(예정, 완료)"
            name="month"
            type="month"
            default="today"
            v-model="searchParam.month">
          </c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            codeGroupCd="MDM_MAINTENANCE_KIND_CD"
            itemText="codeName"
            itemValue="code"
            name="equipmentCheckTypeCd"
            label="정비유형"
            v-model="searchParam.equipmentCheckTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            stepperGrpCd="MIM_MAIN_STATUS_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="maintenanceStatusCd"
            label="진행상태"
            v-model="searchParam.maintenanceStatusCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="설비정비 목록"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      :checkClickFlag="false"
      selection="multiple"
      rowKey="minEquipmentMaintenanceId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn label="계획등록" v-if="editable" icon="add" @btnClicked="onItemClick('A')" />
            <c-btn label="결과등록" v-if="editable" icon="add" @btnClicked="onItemClick('B')" />
            <c-btn label="무계획결과등록" v-if="editable" icon="add" @btnClicked="onItemClick('C')" />
              <c-btn label="삭제" v-if="editable" icon="delete_forever" @btnClicked="remove" />
              <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
      <template v-slot:customArea="{ props }">
        <template v-if="props.row['maintenanceStatusCd']">
          <q-chip outline square
            :color="setTagColor(props.row.maintenanceStatusCd)"
            :class="props.row.maintenanceStatusCd==='MCSC000020' ? 'full-size-chip maintenanceStatusCd-blinking' : 'full-size-chip'"
            >
            <q-icon v-if="props.row.maintenanceStatusCd==='MCSC000020'" name="alarm" style="margin-right:5px" />
            {{setTagName(props.row.maintenanceStatusCd)}}
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "equipment-inspection",
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          equipmentTypeCd: '',
          grade: '',
          inspectionCycleCd: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            style: 'width:100px',
            label: "사업장",
            align: "center",
            sortable: false,
          },
          {
            name: "equipmentCdName",
            field: "equipmentCdName",
            style: 'width:180px',
            label: "설비명(관리번호)",
            align: "left",
            sortable: false,
            type: "link",
          },
          {
            name: "maintenanceStatusCd",
            field: "maintenanceStatusCd",
            label: "진행상태",
            style: 'width:80px',
            align: "center",
            sortable: false,
            type: 'custom',
          },
          {
            name: "maintenanceDueDate",
            field: "maintenanceDueDate",
            label: "정비예정일",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          {
            name: "maintenanceDueUserName",
            field: "maintenanceDueUserName",
            style: 'width:100px',
            label: "정비예정자",
            align: "center",
            sortable: false,
          },
          {
            name: "memDeptEntrantUserName",
            field: "memDeptEntrantUserName",
            style: 'width:110px',
            label: "정비부서입회자",
            align: "center",
            sortable: false,
          },
          {
            name: "maintenanceUserName",
            field: "maintenanceUserName",
            style: 'width:100px',
            label: "실제정비자",
            align: "center",
            sortable: false,
          },
          {
            name: "maintenanceDate",
            field: "maintenanceDate",
            label: "정비완료일",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          {
            name: "inspResultAfterMemName",
            field: "inspResultAfterMemName",
            label: "정비결과",
            style: 'width:80px',
            align: "center",
            sortable: false,
          },
          {
            name: "equipmentCheckTypeName",
            field: "equipmentCheckTypeName",
            label: "정비유형",
            style: 'width:100px',
            align: "center",
            sortable: false,
          },
          {
            name: "pmCycleName",
            field: "pmCycleName",
            label: "정비주기",
            style: 'width:80px',
            align: "center",
            sortable: false,
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            label: "관련법규",
            style: 'width:230px',
            align: "left",
            sortable: false,
          },
          {
            name: "noPlanName",
            field: "noPlanName",
            label: "계획/무계획",
            style: 'width:80px',
            align: "center",
            sortable: false,
          },
        ],
        data: [],
      },
      colorItems: [],
      searchParam: {
        plantCd: null,
        equipmentCheckTypeCd: null,
        maintenanceStatusCd: null,
        startYmd: '',
        endYmd: '',
        noPlanResultEnrollFlag: null,
        equipmentCd: '',
        equipmentTypeCd: '',
        grade: '',
        month: '',
      },
      period: [ '', ''],
      editable: true,
      listUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
      selected: [],
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.colorItems = [
        { stepperMstCd: 'MCSC000005', stepperMstNm: this.$language('계획수립'), colorClass: 'blue' },
        { stepperMstCd: 'MCSC000010', stepperMstNm: this.$language('정비중'), colorClass: 'orange' },
        { stepperMstCd: 'MCSC000015', stepperMstNm: this.$language('정비완료'), colorClass: 'green' },
        { stepperMstCd: 'MCSC000020', stepperMstNm: this.$language('지연'), colorClass: 'red' },
      ]
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.min.maintenance.plan.list.url;
      this.deleteUrl = transactionConfig.sop.min.maintenance.result.delete.url;
      // list setting
      if (this.popupParam.equipmentTypeCd) {
        this.searchParam.equipmentTypeCd = this.popupParam.equipmentTypeCd;
        this.searchParam.equipmentCheckTypeCd = this.popupParam.equipmentCheckTypeCd;
        this.searchParam.grade = this.popupParam.grade;
      }
      this.getList();
    },
    getList() {
      this.searchParam.startYmd = this.period[0];
      this.searchParam.endYmd = this.period[1];
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = row.noPlanResultEnrollFlag === 'N' ? '설비정비결과 상세' : '무계획 설비정비결과 상세'; // 설비정비결과 상세 : 무계획 설비정비결과 상세
      this.popupOptions.param = {
        minEquipmentMaintenanceId: row.minEquipmentMaintenanceId ? row.minEquipmentMaintenanceId : '',
        selectData: [],
      };
      this.popupOptions.target = () =>
      import(`${"./equipmentMaintenanceResultDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    onItemClick(data) {
      let selectData = this.$refs['table'].selected;
      if (data === 'B' || data === 'E') {
        if (selectData && selectData.length === 0) {
          window.getApp.$emit('ALERT', {
            title: '안내', 
            message: '선택된 항목이 없습니다.', 
            type: 'warning',
          });
          return;
        }
      }
      let title = '';
      let param = null;

      if (data === 'A') {
        param = {
          title: "설비정비계획",
          selectData: [],
        };
        title = '설비정비계획 상세'; 
        this.popupOptions.target = () => import(`${"./equipmentMaintenancePlan.vue"}`);
        this.popupOptions.width = '95%';
        this.popupOptions.isFull = false;
      }
      if (data === 'B') {
        param = {
          title: "설비정비결과",
          noPlan: 'N',
          multiple: true,
          selectData: selectData ? selectData: [],
        };
        title = '설비정비결과 상세';
        this.popupOptions.target = () => import(`${"./equipmentMaintenanceResultDetail.vue"}`);
        this.popupOptions.isFull = true;
      }
      if (data === 'C') {
        param = {
          title: "무계획결과",
          noPlan: 'Y',
          minEquipmentMaintenanceId: '',
        };
        title = '무계획결과 상세'; 
        this.popupOptions.target = () => import(`${"./equipmentMaintenanceResult.vue"}`);
        this.popupOptions.isFull = true;
      }
      this.popupOptions.title = title; 
      this.popupOptions.param = param;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          selectData = this.$_.reject(selectData, { maintenanceStatusCd: 'MCSC000015' })
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request((_result) => {
            this.$_.forEach(selectData, item => {
              this.grid.data = this.$_.reject(this.grid.data, { minEquipmentMaintenanceId: item.minEquipmentMaintenanceId })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getList();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$refs['table'].selected = [];
      if (type === 'COMPLETE' || type === 'SAVE') {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
      }
      this.getList();
    },
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
  },
};
</script>
<style>
.maintenanceStatusCd-blinking {
  -webkit-animation:maintenanceStatusCd-blink 2s ease-in-out infinite alternate;
  -moz-animation:maintenanceStatusCd-blink 2s ease-in-out infinite alternate;
  animation:maintenanceStatusCd-blink 2s ease-in-out infinite alternate;
}
@-webkit-keyframes maintenanceStatusCd-blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes maintenanceStatusCd-blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes maintenanceStatusCd-blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>